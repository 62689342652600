import React from "react";
import "./PricingCard.css";
import greenTick from "../../../assests/greenTick.svg";
import redTick from "../../../assests/RedTick.svg";

const PricingCard = (props) => {
  return (
    <div>
      <div className="Pricing">
        <div className="features">
          <p className="planPara">{props.feature}</p>
          <img src="" alt="" />
        </div>
        <div className="plan">
          <p className="planPara">{props.starter}</p>
          <img src={props.starterImg} alt="" />
        </div>
        <div className="plan">
          <p className="planPara">{props.pro}</p>
          <img src={props.proImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
