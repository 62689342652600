import React from "react";
import "./PricingCardMob.css";

const PricingCardMob = ({
  head,
  descleft1,
  descleft2,
  descRight1,
  descRight2,
  imgLeft,
  imgRight,
}) => {
  return (
    <div>
      <div className="pricingCardMob">
        <h3>{head}</h3>
        <div className="planMob">
          <div className="planMobContent borderRightNone">
            <p>{descleft1}</p>
            <p>{descleft2}</p>
            <img src={imgLeft} alt="" />
          </div>
          <div className="planMobContent planBgc">
            <p>{descRight1}</p>
            <p>{descRight2}</p>
            <img src={imgRight} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCardMob;
