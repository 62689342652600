import React from "react";
import WorkBanner from "../Components/HowItWorkComp/WorksBanner/WorkBanner";
import TraditionalHiring from "../Components/HowItWorkComp/TraditionalHiring/TraditionalHiring";
import Faq from "../Components/Reusable/Faq/Faq";
import TalentAcquisition from "../Components/HowItWorkComp/TalentAcquisition/TalentAcquisition";

const HowItWorks = () => {
  return (
    <div>
      <WorkBanner />
      <TraditionalHiring />
      <TalentAcquisition />
      <div className="faqSection">
        <Faq />
      </div>
      {/* <div className="WhyChooseUsSection">
        <WhyChooseUs />
      </div> */}
    </div>
  );
};

export default HowItWorks;
