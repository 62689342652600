import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import About from "../Pages/About";
import JobSeeker from "../Pages/JobSeeker";
import Resources from "../Pages/Resources";
import Pricing from "../Pages/Pricing";
import Blog from "../Pages/Blog";
import Contact from "../Pages/Contact";
import HowItWorks from "../Pages/HowItWorks";
import Test from "../Pages/Test";

const Routing = () => {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-hirein5" element={<About />} />
          <Route path="/talent" element={<JobSeeker />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blog/:blogid" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/how-hirein5-works" element={<HowItWorks />} />
          <Route path="/test" element={<Test />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default Routing;
