import React from "react";
import "./PricingPlan.css";
import greenTick from "../../../assests/greenTick.svg";
import redTick from "../../../assests/RedTick.svg";
import PricingCard from "../../Reusable/PricingCard/PricingCard";
import PricingCardMob from "../../Reusable/PricingCardMob/PricingCardMob";

const PricingPlan = () => {
  return (
    <div>
      <div className="pricingPlan marginTop30">
        <div className="pricingPlanHead">
          <div className="pricingPlanHeadLeft">
            <h1 className="pricingPlanHeadH1">Features</h1>
          </div>
          <div className="pricingPlanHeadRight">
            <h1 className="pricingPlanHeadH1">Starter</h1>
            <p className="pricingPlanHeadPara">Essential Features</p>
            <a href="https://app.hirein5.com/#/clientregister" target="_blank">
              <button className="signButton widthFull padding6px">
                Get Started
              </button>
            </a>
          </div>
          <div className="pricingPlanHeadRight">
            <h1 className="pricingPlanHeadH1">Pro</h1>
            <p className="pricingPlanHeadPara">Maximum Business Value</p>
            <a href="https://app.hirein5.com/#/clientregister" target="_blank">
              <button className="signButton widthFull padding6px">
                Get Started
              </button>
            </a>
            <h5>Recommended</h5>
          </div>
        </div>
        <div className="webHandler">
          <div className="pricingPlanContent">
            <h2>USER ACCESS</h2>
            <PricingCard
              feature="Portal Login Access"
              starter="1 User"
              pro="2 Global users"
              starterImg=""
              proImg=""
            />
          </div>
          <div className="pricingPlanContent">
            <h2>ASSESSMENTS</h2>
            <PricingCard
              feature="Access to candidate basic data"
              starter=""
              pro=""
              starterImg={greenTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Personality assessment"
              starter=""
              pro=""
              starterImg={redTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Access to BGV"
              starter=""
              pro=""
              starterImg={greenTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Access to External Technical Assessments"
              starter=""
              pro=""
              starterImg={redTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Access to interview videos"
              starter=""
              pro=""
              starterImg={greenTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Candidate’s availability"
              starter=""
              pro=""
              starterImg={greenTick}
              proImg={greenTick}
            />
          </div>
          <div className="pricingPlanContent">
            <h2>WORK HISTORY</h2>
            <PricingCard
              feature="Access to Candidate's Work Experience"
              starter=""
              pro=""
              starterImg={greenTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Candidate’s project details"
              starter=""
              pro=""
              starterImg={greenTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Candidate’s travel history"
              starter=""
              pro=""
              starterImg={redTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Candidate’s active Visa status"
              starter=""
              pro=""
              starterImg={redTick}
              proImg={greenTick}
            />
          </div>
          <div className="pricingPlanContent">
            <h2>ADD-ONS</h2>
            <PricingCard
              feature="Online interview scheduling"
              starter="Limited to 10 interviews"
              pro="Unlimited scheduling"
              starterImg=""
              proImg=""
            />
            <PricingCard
              feature="Reserve candidates at a small fee that will be adjusted against the first invoice"
              starter=""
              pro="For 5 days from date of reserving"
              starterImg={redTick}
              proImg=""
            />
            <PricingCard
              feature="Candidate’s availability for onsite assignment"
              starter=""
              pro=""
              starterImg={greenTick}
              proImg={greenTick}
            />
            <PricingCard
              feature="Hire candidates directly on payroll after 6 months"
              starter=""
              pro="Yes, post 6 months of contract"
              starterImg={redTick}
              proImg=""
            />
          </div>
        </div>
        {/* <div className="mobileHandler"> */}
          <div className="pricingPlanMob">
            <div className="pricingPlanMobHead">
              <h1>Features</h1>
              <div className="pricingPlanMobPlan">
                <h2 className="borderRightNone">Starter</h2>
                <h2 className="borderLeftNone planBgc">Pro</h2>
              </div>
            </div>
            <PricingCardMob
              head="Number of users"
              descleft1="1 User /"
              descleft2="No concurrent login"
              descRight1="2 Global users /"
              descRight2="2 Concurrent logins/user"
              imgLeft=""
              imgRight=""
            />
            <PricingCardMob
              head="Access to candidate basic data"
              imgLeft={greenTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Personality assessment"
              imgLeft={redTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Access to BGV"
              imgLeft={greenTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Access to External Technical Assessments"
              imgLeft={redTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Access to interview videos"
              imgLeft={greenTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Candidate’s availability"
              imgLeft={greenTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Candidate work history"
              imgLeft={greenTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Candidate’s travel history"
              imgLeft={redTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Candidate’s active Visa status"
              imgLeft={redTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Online interview scheduling"
              descleft1="Limited to 10 interviews"
              descleft2=""
              descRight1="Unlimited scheduling"
              descRight2=""
            />
            <PricingCardMob
              head="Reserve candidates at a small fee that will be adjusted against the first invoice"
              descRight1="For 5 days from date of reserving"
              imgLeft={redTick}
            />
            <PricingCardMob
              head="Candidate’s availability for onsite assignment"
              imgLeft={greenTick}
              imgRight={greenTick}
            />
            <PricingCardMob
              head="Hire candidates directly on payroll after 6 months"
              descRight1="Yes, post 6 months of"
              descRight2="contract"
              imgLeft={redTick}
            />
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default PricingPlan;
