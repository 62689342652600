/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import "./HomeBanner.css";
import bannerImgLeft from "../../../assests/HeroLeft.svg";
import bannerImgRight from "../../../assests/HeroRight.svg";
import bannerImgLeftMob from "../../../assests/heroLeftMob.svg";
import bannerImgRightMob from "../../../assests/heroRightMob.svg";
import discoverImg from "../../../assests/discover.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";

const HomeBanner = () => {
  gsap.registerPlugin(ScrollTrigger); //calling scroll trigger plugin
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Main div is pinned

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".homeBanner",
        start: "-30% top",
        end: "50% top",
        scrub: 1,
        // markers: true,
      },
    });
    tl1.to(".homeBannerImg", { duration: 1, opacity: 1 });

    // const tl2 = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".homeBanner",
    //     start: "-20% top",
    //     end: "20% top",
    //     scrub: 1,
    //     // markers: true,
    //   },
    // });
    // tl2.to(".homeBanner", { duration: 1, y: -100, opacity: 1 });
  }, []);

  const positionAwareHandler = (e) => {
    const x = e.pageX - document.querySelector(`#${e.target.id}`).offsetLeft;
    const y = e.pageY - document.querySelector(`#${e.target.id}`).offsetTop;
    document
      .querySelector(`#${e.target.id}`)
      .style.setProperty("--x", x + "px");
    document
      .querySelector(`#${e.target.id}`)
      .style.setProperty("--y", y + "py");
  };

  // schedule a demo

  const dispatch = useDispatch();

  const demoHandler = () => {
    dispatch(storeAction.isPopUpHander("demo"));
  };

  return (
    <div>
      <div id="homeBanner" className="homeBanner">
        <img
          data-aos="fade-right"
          data-aos-duration="1500"
          // data-aos-delay="300"
          className="webHandler"
          src={bannerImgLeft}
          alt=""
        />
        <img
          data-aos="fade-right"
          data-aos-duration="1500"
          className="homeBannerImgLeft mobileHandler"
          src={bannerImgLeftMob}
          alt=""
        />
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="600"
          className="homeBannerContent"
        >
          <h1>
            Hire tech talent <br />
            in less than <span>5 hours</span>
          </h1>
          <p className="webHandler">
            Designed to simplify the recruitment journey for organizations and
            <br />
            connect them with tech talent swiftly and seamlessly
          </p>
          <p className="mobileHandler">
            Designed to simplify the recruitment journey for organizations and
            connect them with tech talent swiftly and seamlessly
          </p>
          <div className="homeBannerContentButton">
            <a
              onClick={demoHandler}
              onMouseMove={positionAwareHandler}
              id="signupBtn"
              className="signButton"
              // href="https://app.hirein5.com/#/clientsignup"
              target="_blank"
            >
              Book a Demo{" "}
            </a>
            {/* <a
              onMouseMove={positionAwareHandler}
              id="loginBtn"
              className="loginButton loginButtonColor"
              href="https://app.hirein5.com/#/login"
              target="_blank"
            >
              Login
            </a> */}
          </div>
        </div>
        <img
          data-aos="fade-left"
          data-aos-duration="1500"
          className="homeBannerImgRight mobileHandler"
          src={bannerImgRightMob}
          alt=""
        />
        <img
          data-aos="fade-left"
          data-aos-duration="1500"
          // data-aos-delay="600"
          className="webHandler"
          src={bannerImgRight}
          alt=""
        />
      </div>
      {/* <div className="homeBannerImg webHandler">
        <img src={discoverImg} alt="" />
      </div> */}
      {/* <div className="homeBannerImgMob mobileHandler">
        <img src={discoverImg} alt="" />
      </div> */}
    </div>
  );
};

export default HomeBanner;
