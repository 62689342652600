import React from "react";
import "./ConsiderHire.css";
import considerImg from "../../../assests/consider.svg";

const ConsiderHire = () => {
  return (
    <div>
      <div className="considerHire sectionWidth">
        <div className="considerHireHead">
          <h1>Why Consider Hirein5</h1>
          <img src={considerImg} alt="" />
          <div className="considerHireFlex">
            <div className="considerHireFlexContent">
              <h2>Technology</h2>
              <p>
                We are powered by amazing and scalable tech that allows
                organizations to hire employees across the globe in less than 5
                hours.
              </p>
            </div>
            <div className="considerHireFlexContent">
              <h2>Experience</h2>
              <p>
                From requirements to onboarding, we are simplifying the whole
                hiring experience by connecting right talent with right
                opportunities.  
              </p>
            </div>
            <div className="considerHireFlexContent1">
              <h2>Expertise</h2>
              <p>
                A Highly Experienced team that ensures that our clients get
                highly-vetted individuals who are also the right culture fit.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsiderHire;
