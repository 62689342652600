// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.acquisitionCard {
    background-color: var(--white);
    height: 100%;
    border-radius: 20px;
    padding: 20px;
    text-align: start;
}

.acquisitionCard img {
    height: 35px;
    margin-bottom: 15px;
}

.acquisitionCard p {
    font-size: 20px;
    font-family: var(--primaryColor);
    color: var(--primaryTextColor);
    font-weight: 500;
}

@media(max-width: 1200px){
    .acquisitionCard p{
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/AcquisitionCard/AcquisitionCard.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gCAAgC;IAChC,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".acquisitionCard {\n    background-color: var(--white);\n    height: 100%;\n    border-radius: 20px;\n    padding: 20px;\n    text-align: start;\n}\n\n.acquisitionCard img {\n    height: 35px;\n    margin-bottom: 15px;\n}\n\n.acquisitionCard p {\n    font-size: 20px;\n    font-family: var(--primaryColor);\n    color: var(--primaryTextColor);\n    font-weight: 500;\n}\n\n@media(max-width: 1200px){\n    .acquisitionCard p{\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
