import React from "react";
import "./TraditionalHiring.css";
import traditionalHiringImg from "../../../assests/TraditionalHiringImg.svg";
import traditionalHiringImgMob from "../../../assests/longImageMob.svg";
import smilyHappy from "../../../assests/SimilyHappy.svg";
import smilyCalm from "../../../assests/SimilyCalm.svg";
import smilyNeutral from "../../../assests/SmilyNeutral.svg";
import smilySad from "../../../assests/SmilySad.svg";
import SmilyDisatisfied from "../../../assests/SmilyDisatisfied.svg";
import smilyStressed from "../../../assests/SmilyStressed.svg";
import hireNowImg from "../../../assests/HireNowImg.svg";
import step2 from "../../../assests/step2.svg";
// import step2Mob from "../../../assests/step2Mob.svg";
import step3 from "../../../assests/step3.svg";
import step4 from "../../../assests/step4.svg";
import step5 from "../../../assests/step5.svg";
import whiteTick from "../../../assests/whiteTick.svg";
import { IoMdInformationCircleOutline } from "react-icons/io";

const TraditionalHiring = () => {
  return (
    <div>
      <div className="TraditionalHiring ">
        <div className="traditionalHiringContainer">
          <div className="traditionalHiringContainerHead transparent">
            <h1>How traditional hiring works</h1>
            <p>
              Breaking free from the unreliable, costly maze of traditional
              hiring
            </p>
          </div>
          <div className="traditionalHiringBody">
            <h2>TRADITIONAL HIRING</h2>
          </div>
          <div className="traditionalHiringContentCard positionHappy">
            <div className="traditionalHiringContentCardFlex">
              <img src={smilyHappy} alt="" />
              <h2>STEP 1</h2>
            </div>
            <h3>Sourcing Candidates’ Profiles</h3>
            <p>5-7 Days</p>
          </div>
          <div className="traditionalHiringContentCard positionCalm">
            <div className="traditionalHiringContentCardFlex1">
              <img src={smilyCalm} alt="" />
              <h2>STEP 2</h2>
            </div>
            <h3>Shortlisting Candidates </h3>
            <p>5 Days</p>
          </div>
          <div className="traditionalHiringContentCard positionNeutral">
            <div className="traditionalHiringContentCardFlex">
              <img src={smilyNeutral} alt="" />
              <h2>STEP 3</h2>
            </div>
            <h3>Business Interviews (Technical and Non-Technical)</h3>
            <p>5 Days</p>
          </div>
          <div className="traditionalHiringContentCard positionDisatisfied">
            <div className="traditionalHiringContentCardFlex1">
              <img src={SmilyDisatisfied} alt="" />
              <h2>STEP 4</h2>
            </div>
            <h3>Candidate BGV </h3>
            <p>1-2 Weeks</p>
          </div>
          <div className="traditionalHiringContentCard positionSad">
            <div className="traditionalHiringContentCardFlex">
              <img src={smilySad} alt="" />
              <h2>STEP 5</h2>
            </div>
            <h3>Offer Rollouts</h3>
            <p>1-2 Weeks</p>
          </div>
          <div className="traditionalHiringContentCard positionStressed">
            <div className="traditionalHiringContentCardFlex1">
              <img src={smilyStressed} alt="" />
              <h2>STEP 6</h2>
            </div>
            <h3>Candidate Joining</h3>
            <p> 4-8 Weeks</p>
          </div>
          <img
            className="traditionalHiringImg webHandler"
            src={traditionalHiringImg}
            alt=""
          />
          <img
            className="traditionalHiringImgMob mobileHandler"
            src={traditionalHiringImgMob}
            alt=""
          />
          <div className="traditionalCostContainer">
            <div className="traditionalCostContainerContent costLeftWidth">
              <IoMdInformationCircleOutline className="costInfoIcon" />
              <h1>Cost of Traditional Hiring</h1>
            </div>
            <div className="traditionalCostContainerContent">
              <h3>
                Agency Fees + Job Portal Fees + BGV Cost + Assessment Fees +
                Candidate’s Salary + Infrastructure Cost.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="containerPosition">
        <div className="fiveStepsContainer">
          <div className="traditionalHiringContainerHead">
            <h1 className="textColor">Hire in less than </h1>
            <h1 className="textColor">5 hours with Hirein5</h1>
            <p className="textColor">
              Discover how Hirein5 delivers value and efficiency across hiring
              process. 
            </p>
          </div>
          <div className="hireIn5Step1Card Step1">
            <div className="hireIn5Step1CardImg hireIn5Step1CardImgColor1">
              <img
                className="hireIn5Step1CardImgFirstImg"
                src={hireNowImg}
                alt=""
              />
              <img
                className="hireIn5Step1CardImgSecImg"
                src={hireNowImg}
                alt=""
              />
            </div>
            <div className="hireIn5Step1CardContent">
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                Signing Up with Hirein5 is as{" "}
              </h1>
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                as easy as counting 1-2-3
              </h1>
              <h1 className="hireIn5Step1CardHeadingTab">
                Signing Up with Hirein5 is as easy as counting 1-2-3
              </h1>
              <h1 className="hireIn5Step1CardHeading mobileHandler">
                Signing Up with Hirein5 is as easy as counting 1-2-3
              </h1>
              <p className="hireIn5Step1CardDuration">4-8 Weeks</p>
            </div>
          </div>
          <h4 className="stepContainer step1">STEP 1</h4>
          <div className="hireIn5Step1Card Step2">
            {/* <div className="hireIn5Step1CardImg"> */}
            <img className="widthFull" src={step2} alt="" />
            {/* </div> */}
            <div className="hireIn5Step1CardContent1">
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                Discover profiles based on your
              </h1>
              <h1 className="hireIn5Step1CardHeading webHandler tab">needs</h1>
              <h1 className="hireIn5Step1CardHeadingTab">
                Discover profiles based on your needs
              </h1>
              <h1 className="hireIn5Step1CardHeading mobileHandler">
                Discover profiles based on your needs
              </h1>
              <p className="hireIn5Step1CardDuration">4-8 Weeks</p>
            </div>
          </div>
          <h4 className="stepContainer step2">STEP 2</h4>
          <div className="hireIn5Step1Card Step3">
            {/* <div className="hireIn5Step1CardImg"> */}
            <img className="widthFull" src={step3} alt="" />
            {/* </div> */}
            <div className="hireIn5Step1CardContent">
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                360-view of candidates
              </h1>
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                information in one screen
              </h1>
              <h1 className="hireIn5Step1CardHeadingTab">
                360-view of candidates information in one screen
              </h1>
              <h1 className="hireIn5Step1CardHeading mobileHandler">
                360-view of candidates information in one screen
              </h1>
              <p className="hireIn5Step1CardDuration">4-8 Weeks</p>{" "}
            </div>
          </div>
          <h4 className="stepContainer step3">STEP 3</h4>
          <div className="hireIn5Step1Card Step4">
            {/* <div className="hireIn5Step1CardImg"> */}
            <img className="widthFull" src={step4} alt="" />
            {/* </div> */}
            <div className="hireIn5Step1CardContent1">
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                Interviewing/Reserving features
              </h1>
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                at your fingertips
              </h1>
              <h1 className="hireIn5Step1CardHeadingTab">
                Interviewing/Reserving features at your fingertips
              </h1>
              <h1 className="hireIn5Step1CardHeading mobileHandler">
                Interviewing/Reserving features at your fingertips
              </h1>
              <p className="hireIn5Step1CardDuration">4-8 Weeks</p>{" "}
            </div>
          </div>
          <h4 className="stepContainer step4">STEP 4</h4>
          <div className="hireIn5Step1Card Step5">
            {/* <div className="hireIn5Step1CardImg"> */}
            <img className="widthFull" src={step5} alt="" />
            {/* </div> */}
            <div className="hireIn5Step1CardContent">
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                Sign Statement of Work (SOW)
              </h1>
              <h1 className="hireIn5Step1CardHeading webHandler tab">
                and get started.
              </h1>
              <h1 className="hireIn5Step1CardHeadingTab">
                Sign Statement of Work (SOW) and get started.
              </h1>
              <h1 className="hireIn5Step1CardHeading mobileHandler">
                Sign Statement of Work (SOW) and get started.
              </h1>
              <p className="hireIn5Step1CardDuration">4-8 Weeks</p>
            </div>
          </div>
          <h4 className="stepContainer step5">STEP 5</h4>
          <div className="hirein5cost">
            <img src={whiteTick} alt="" />
            <h1>Cost of Hirein5</h1>
            <p>Subscription Fee + Monthly Candidate Fees</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraditionalHiring;
