/* eslint-disable eqeqeq */
import React, { useEffect } from "react";

/* eslint-disable react/jsx-no-target-blank */
import "./HiringSection.css";
import SectionHeading from "../../Reusable/SectionHeading/SectionHeading";
import PercentCard from "../../Reusable/PercentCard/PercentCard";
import FlowImg from "../../../assests/flow.svg";
import FlowImgMob from "../../../assests/flowMob.svg";
import bestCandidateBg from "../../../assests/bestCandidateBg.svg";
import tabImg from "../../../assests/FiveTabImg.svg";
import candidateLeft from "../../../assests/CandidateLeft1.svg";
import candidateRight from "../../../assests/CandidateRight1.svg";
import candidateCenter from "../../../assests/CandidateCenter.svg";
import FaqCard from "../../Reusable/FaqCard/FaqCard";
import faq from "../../../assests/faq.svg";
import faq1 from "../../../assests/faqOne.svg";
import faq2 from "../../../assests/faqTwo.svg";
import faq3 from "../../../assests/faqThree.svg";
import faq4 from "../../../assests/faqFouth.svg";
import faq5 from "../../../assests/faqFive.svg";
import reqBgImg from "../../../assests/reqBg.svg";
import reqBgImgMob from "../../../assests/reqImgBgMob.svg";
import { useState } from "react";
import star from "../../../assests/star.svg";
import polo from "../../../assests/polo.svg";
import profileImg from "../../../assests/Profile.svg";
import profileImgMob from "../../../assests/profileMob.svg";
import video from "../../../assests/vedioResume.svg";
import reserveImg from "../../../assests/reserve.svg";
import triangle from "../../../assests/triangle.svg";
import videoCall from "../../../assests/VideoCall.svg";
import videoCallMob from "../../../assests/videocallMob.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import Faq from "../../Reusable/Faq/Faq";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";

const HiringSection = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    AOS.init();
  }, []);
  const [isShow, setIsShow] = useState("1");
  const faqHandler = (e) => {
    setIsShow(e.target.id);
  };

  const demoHandler = () => {
    dispatch(storeAction.isPopUpHander("demo"));
  };
  return (
    <div>
      <div className="hiringSection">
        <div className="hiringSectionBody sectionWidth mainbody">
          <SectionHeading
            head1="Hirein5 simplifies "
            head2="tech hiring"
            desc1="At Hirein5, we understand challenges of technology hiring. That's why we offer a solution to connect you with qualified candidates who are ready to onboard."
            desc2=""
            desc3=""
            descMob="At Hirein5, we understand challenges of technology hiring. That's why we offer a solution to connect you with qualified candidates who are ready to onboard."
          />
          <div className="hiringSectionBodyFlex">
            <div className="hiringSectionBodyFlexLeft">
              <PercentCard
                percent="60% employers "
                percentDesc="are worried about unfilled job openings. "
                Head=""
                content=""
                button=""
                IconClass="percentIconNone"
              />
              <PercentCard
                percent="4 in 5 employers "
                percentDesc="report difficulty finding the talent."
                Head=""
                content=""
                button=""
                IconClass="percentIconNone"
              />
              <PercentCard
                percent="$4000"
                percentDesc="Average cost of hiring each candidate. "
                Head=""
                content=""
                button=""
                IconClass="percentIconNone"
              />
              <PercentCard
                percent="81% recruiters "
                percentDesc="81% of professional recruiters say it’s challenging to fill roles ."
                Head=""
                content=""
                button=""
                IconClass="percentIconNone"
              />
            </div>
            {/* this image is for mobile component */}
            <img className="flowImgMob" src={FlowImgMob} alt="" />
            <div className="hiringSectionBodyFlexRight">
              <PercentCard
                percent=""
                percentDesc=""
                Head="Fast-track Hiring "
                content="We have streamlined hiring into a single platform, covering sourcing, interviews, & onboarding, to reduce effort & speed up hiring."
                button=""
                IconClass="percentIconNone"
              />
              <PercentCard
                percent=""
                percentDesc=""
                Head="Platform Pricing"
                content="Transparent pricing with no surprises. Pay for platform access and start matching candidates to the job requirements."
                button="Contact Sales"
                IconClass="percentIcon"
              />
              <PercentCard
                percent=""
                percentDesc=""
                Head="Single View Evaluation"
                content="Our unique process allows recruiters to evaluate the suitability of the profile without going anywhere else. "
                button="Sign up on Hirein5"
                IconClass="percentIcon"
              />
              <PercentCard
                percent=""
                percentDesc=""
                Head="Immediate joiners"
                content="Our top talent is ready to join your team immediately. No-drop outs or waiting times to get your project underway."
                button=""
                IconClass="percentIconNone"
              />
            </div>
            {/* this image is web */}
            <img className="flowImg" src={FlowImg} alt="" />
          </div>
        </div>
        <div className="bestCandidate">
          <div data-aos="fade-up" data-aos-duration="1500" className="">
            <SectionHeading
              head1="Get the best candidates"
              head2="screened and ready to onboard"
              desc1="We will help match our pre-screened global candidates with  your requirements through and through"
              desc2=""
              desc3=""
              descMob="We will help match our pre-screened global candidates with your requirements
            through and through"
            />
          </div>
          <div className="bestCandidateBody mainbody">
            <img
              // data-aos="fade-down"
              // data-aos-delay="900"
              // data-aos-duration="1500"
              className="bestCandidateBg webHandler"
              src={bestCandidateBg}
              alt=""
            />
            <img
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
              className="tabImg"
              src={tabImg}
              alt=""
            />
            <img
              data-aos="fade-right"
              // data-aos-delay="600"
              data-aos-duration="1500"
              className="candidateLeft webHandler"
              src={candidateLeft}
              alt=""
            />
            <img
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-duration="1500"
              className="candidateRight webHandler"
              src={candidateRight}
              alt=""
            />
            <img
              data-aos="fade-right"
              data-aos-delay="600"
              data-aos-duration="1500"
              className="candidateCenter webHandler"
              src={candidateCenter}
              alt=""
            />
            {/* this card is for mobile */}
            <div className="bestCandidateBodyMob">
              <img
                data-aos="fade-right"
                // data-aos-delay="300"
                data-aos-duration="1500"
                className="candidateLeftMob"
                src={candidateLeft}
                alt=""
              />
              <img
                data-aos="fade-right"
                data-aos-delay="600"
                data-aos-duration="1500"
                className="candidateRightMob"
                src={candidateRight}
                alt=""
              />
              <img
                data-aos="fade-right"
                data-aos-delay="900"
                data-aos-duration="1500"
                className="candidateCenterMob"
                src={candidateCenter}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="hassleHiring mainbody">
          <div className="hassleHiringBody">
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
              className="hassleHiringContent1"
            >
              <div className="hassleHiringHead">
                <div className="hassleHiringHeadContent">
                  <h1>Assessments</h1>
                  <h1>Work History</h1>
                  <h1>Availability</h1>
                  <h1>Ethics</h1>
                </div>
                <div className="hassleHiringHeadContent1">
                  <h1>Travel History</h1>
                  <h1>Hacker Rank Score</h1>
                  <h1>Availability</h1>
                  <h1>Background Verification</h1>
                </div>
              </div>
              <div className="hassleHiringImg">
                <img src={star} alt="" />
                <h2>One view of candidate </h2>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="300"
              className="hassleHiringContent2"
            >
              <div className="hassleHiringContent2Left">
                <img src={polo} alt="" />
                <h1>Transparent Pricing</h1>
              </div>
              <img className="profileImg" src={profileImg} alt="" />
            </div>
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
              className="hassleHiringContent3"
            >
              <img src={video} alt="" />
              <h1>Video resume to asses a candidate’s soft skills</h1>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="300"
              className="hassleHiringContent4"
            >
              <SectionHeading
                head1="Start your hassle-free hiring journey"
                head2=""
                desc1=""
                desc2=""
              />
              <button
                onClick={demoHandler}
                className="signButtonReq marginTop30"
              >
                Schedule a demo
              </button>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="300"
              className="hassleHiringContent5"
            >
              <div className="hassleHiringContent5Img">
                <img src={reserveImg} alt="" />
              </div>
              <h1>Reserve candidates of your liking, with just 1-click </h1>
            </div>
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
              className="hassleHiringContent6"
            >
              <div className="hassleHiringContent6Left">
                <img src={triangle} alt="" />
                <h1>Schedule online interview meetings</h1>
              </div>
              <div className="hassleHiringContent6Right">
                <img src={videoCall} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="hassleHiringMob sectionWidth">
          <div className="hassleHiringBodyMob">
            <div
              data-aos="fade-up"
              // data-aos-delay="600"
              data-aos-duration="1000"
              className="hassleHiringContent4"
            >
              <SectionHeading
                head3="Start your hassle-free"
                head4="hiring journey"
                desc1=""
                desc2=""
                descMob=""
              />
              <button
                onClick={demoHandler}
                className="signButtonReq marginTop30"
              >
                Schedule a demo
              </button>
            </div>
            <div
              data-aos="fade-up"
              // data-aos-delay="600"
              data-aos-duration="1000"
              className="hassleHiringContent1"
            >
              <div className="hassleHiringHead">
                <div className="hassleHiringHeadContent">
                  <h1>Assessments</h1>
                  <h1>Work History</h1>
                  <h1>Availability</h1>
                  <h1>Ethics</h1>
                </div>
                <div className="hassleHiringHeadContent1">
                  <h1>Travel History</h1>
                  <h1>Hacker Rank Score</h1>
                  <h1>Availability</h1>
                  <h1>Background Verification</h1>
                </div>
              </div>
              <div className="hassleHiringImg">
                <img src={star} alt="" />
                <h2>One view of candidate </h2>
              </div>
            </div>
            <div
              data-aos="fade-up"
              // data-aos-delay="600"
              data-aos-duration="1000"
              className="hassleHiringContent2"
            >
              <div className="hassleHiringContent2Left">
                <img src={polo} alt="" />
                <h1>Transparent Pricing</h1>
              </div>
              <img className="profileImg webHandler" src={profileImg} alt="" />
              <img className="profileImgMob" src={profileImgMob} alt="" />
            </div>
            <div
              data-aos="fade-up"
              // data-aos-delay="600"
              data-aos-duration="1000"
              className="hassleHiringContent3"
            >
              <img src={video} alt="" />
              <h1>Video resume to asses a candidate’s soft skills</h1>
            </div>
            <div
              data-aos="fade-up"
              // data-aos-delay="600"
              data-aos-duration="1000"
              className="hassleHiringContent6"
            >
              <div className="hassleHiringContent6Left">
                <img src={triangle} alt="" />
                <h1>Schedule online interview meetings</h1>
              </div>
              <div className="hassleHiringContent6Right">
                <img className="webHandler" src={videoCall} alt="" />
                <img className="mobileHandler" src={videoCallMob} alt="" />
              </div>
            </div>
            <div
              data-aos="fade-up"
              // data-aos-delay="600"
              data-aos-duration="1000"
              className="hassleHiringContent5"
            >
              <div className="hassleHiringContent5Img">
                <img src={reserveImg} alt="" />
              </div>
              <h1>Reserve candidates of your liking, with just 1-click </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="hiringPlatform webHandler">
        <div
          data-aos="fade-up"
          // data-aos-delay="1300"
          data-aos-duration="1000"
          className="hiringPlatformHead"
        >
          <SectionHeading
            head1="How our hiring"
            head2="platform works"
            desc1=""
            desc2=""
            desc3=""
          />
        </div>
        <div className="hiringPlatformFlex sectionWidth mainbody">
          <div className="hiringPlatformFlexLeft">
            <div
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-duration="1000"
              data-aos-once="true"
              className=""
            >
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="1"
                head="Sign Up using our Hirein5 Platform"
                desc="Simple verification process connects you with our team ."
              />
            </div>
            <div
              data-aos="fade-right"
              data-aos-delay="500"
              data-aos-duration="1000"
              data-aos-once="true"
              className=""
            >
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="2"
                head="Share your requirements"
                desc="Discuss your requirements, Sign Contract, Account Is created. "
              />
            </div>
            <div
              data-aos="fade-right"
              data-aos-delay="700"
              data-aos-duration="1000"
              data-aos-once="true"
              className=""
            >
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="3"
                head="Browse through our talent pool"
                desc="Pre-vetted candidates, ready to be hired, with transparent rate cards "
              />
            </div>
            <div
              data-aos="fade-right"
              data-aos-delay="900"
              data-aos-duration="1000"
              data-aos-once="true"
              className=""
            >
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="4"
                head="Reserve the candidate"
                desc="Book an interview online or reserve a candidate on the platform. "
              />
            </div>
            <div
              data-aos="fade-right"
              data-aos-delay="1100"
              data-aos-duration="1000"
              data-aos-once="true"
              className=""
            >
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="5"
                head="Sign the SoW"
                desc="Sign the SoW online and get started in a matter of hours. "
              />
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="1300"
            data-aos-duration="1000"
            data-aos-once="true"
            className="hiringPlatformFlexRight"
          >
            {isShow == "" && <img src={faq} alt="" />}
            {isShow == "1" && <img src={faq1} alt="" />}
            {isShow == "2" && <img src={faq2} alt="" />}
            {isShow == "3" && <img src={faq3} alt="" />}
            {isShow == "4" && <img src={faq4} alt="" />}
            {isShow == "5" && <img src={faq5} alt="" />}

            {/* <h1>{isShow}</h1> */}
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className="hiringPlatform mobileHandler">
        <div className="hiringPlatformHead">
          <SectionHeading
            head1="How our hiring"
            head2="platform works"
            headClass="colorWhite"
            desc1="See a real-time example of how our hiring engine works"
            desc2=""
            desc3=""
            descMob="See a real-time example of
            how our hiring engine works"
          />
        </div>
        <div className="hiringPlatformFlex sectionWidth">
          <div className="hiringPlatformFlexLeft">
            <div className="marginBottom40">
              <div className="hiringPlatformFlexRight">
                <img src={faq1} alt="" />
                {/* <h1>{isShow}</h1> */}
              </div>
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="1"
                head="Sign Up using our Hirein5 Platform "
                desc="Simple verification process connects you with our team ."
              />
            </div>
            <div className="marginBottom40">
              <div className="hiringPlatformFlexRight ">
                <img src={faq2} alt="" />
                {/* <h1>{isShow}</h1> */}
              </div>
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="2"
                head="Mention your requirements"
                desc="Discuss your requirements, Sign Contract, Account Is created. "
              />
            </div>
            <div className="marginBottom40">
              <div className="hiringPlatformFlexRight">
                <img src={faq3} alt="" />
                {/* <h1>{isShow}</h1> */}
              </div>
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="3"
                head="Browse through our talent pool"
                desc="Pre-vetted candidates, ready to be hired, with transparent rate cards "
              />
            </div>
            <div className="marginBottom40">
              <div className="hiringPlatformFlexRight">
                <img src={faq4} alt="" />
                {/* <h1>{isShow}</h1> */}
              </div>
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="4"
                head="Connect with the talent"
                desc="Book an interview online or reserve a candidate on the platform. "
              />
            </div>
            <div className="marginBottom40">
              <div className="hiringPlatformFlexRight">
                <img src={faq5} alt="" />
                {/* <h1>{isShow}</h1> */}
              </div>
              <FaqCard
                fun={faqHandler}
                state={isShow}
                setState={setIsShow}
                number="5"
                head="Formal Onboarding Process "
                desc="Sign the SoW online and get started in a matter of hours. "
              />
            </div>
          </div>
        </div>
      </div>
      <Faq />
      {/* <div className="faq">
        <div className="faqHead">
          <SectionHeading
            head1="Frequently Asked Questions"
            head2=""
            desc1=""
            desc2=""
            desc3=""
          />
        </div>
        <div className="faqBody">
          <FaqCard
            fun={faqHandler1}
            state={isShow1}
            setState={setIsShow1}
            number="6"
            numClass="numberNone"
            head="Mention your requirements"
            headClass="headBold"
            desc="No commitment or credit card needed. Let us know if you need 
              any help to sign up and experience it. "
          />
          <FaqCard
            fun={faqHandler1}
            state={isShow1}
            setState={setIsShow1}
            number="7"
            numClass="numberNone"
            head="Mention your requirements"
            headClass="headBold"
            desc="No commitment or credit card needed. Let us know if you need 
              any help to sign up and experience it. "
          />
          <FaqCard
            fun={faqHandler1}
            state={isShow1}
            setState={setIsShow1}
            number="8"
            numClass="numberNone"
            head="Mention your requirements"
            headClass="headBold"
            desc="No commitment or credit card needed. Let us know if you need 
              any help to sign up and experience it. "
          />
          <FaqCard
            fun={faqHandler1}
            state={isShow1}
            setState={setIsShow1}
            number="9"
            numClass="numberNone"
            head="Mention your requirements"
            headClass="headBold"
            desc="No commitment or credit card needed. Let us know if you need 
              any help to sign up and experience it. "
          />
          <FaqCard
            fun={faqHandler1}
            state={isShow1}
            setState={setIsShow1}
            number="10"
            numClass="numberNone"
            head="Mention your requirements"
            headClass="headBold"
            desc="No commitment or credit card needed. Let us know if you need 
              any help to sign up and experience it. "
          />
        </div>
      </div> */}

      <div className="requirements">
        <div className="requirementsContent sectionWidth mainbody">
          <h1>From Requirements to</h1>
          <h1>Onboarding in 5 hours</h1>
          <p>
            Curated shortlists of perfect-match candidates rapidly delivered.
            Professionals committed and excited to start immediately.
          </p>
          <div className="requirementButton">
            <button className="loginButtonReq">
              <a
                href="https://app.hirein5.com/#/clientregister"
                target="_blank"
              >
                Sign Up
              </a>
            </button>
            {/* <button className="signButtonReq">
              <a href="https://app.hirein5.com/#/login" target="_blank">
                Login
              </a>
            </button> */}
          </div>
          <img className="reqBgImg webHandler" src={reqBgImg} alt="" />
        </div>
        <img className="reqBgImgMob mobileHandler" src={reqBgImgMob} alt="" />
      </div>
    </div>
  );
};

export default HiringSection;
