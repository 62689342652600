import React from "react";
import "./CarrierSuccessCard.css";

const CarrierSuccessCard = (props) => {
  return (
    <div>
      <div className={props.cardClass}>
        <div className="carrierSuccessCardHead">
          <img src={props.image} alt="" />
          <h1>{props.head1}</h1>
          <h1>{props.head2}</h1>
        </div>
        <div className="">
          <p>{props.desc1}</p>
          <p>{props.desc2}</p>
          <p>{props.desc3}</p>
        </div>
      </div>
    </div>
  );
};

export default CarrierSuccessCard;
