import React from "react";
import "./TalentAcquisition.css";
import AcquisitionCard from "../../Reusable/AcquisitionCard/AcquisitionCard";
import AcquisitionStar from "../../../assests/AcquisitionStar.svg";
import AcquisitionSearch from "../../../assests/AcquisitionSearch.svg";
import AcquisitionTime from "../../../assests/AcquisitionTime.svg";
import AcquisitionCash from "../../../assests/AcquisitionCash.svg";
import AcquisitionHand from "../../../assests/AcquisitionHand.svg";
import AcquisitionGlobe from "../../../assests/AcquisitionGlobe.svg";

const TalentAcquisition = () => {
  return (
    <div>
      <div className="TalentAcquisition ">
        <div className="traditionalHiringContainerHead transparent">
          <h1 className="textColor">
            Creating Value Across Talent Acquisition Cycle
          </h1>

          <p className="textColor marginBottom-15">
            No more unwanted costs from your hiring process with our unique
            features that works seamlessly for all your hiring needs.
          </p>
        </div>
        <div className="acquisitionContainer sectionWidth">
          <AcquisitionCard Img={AcquisitionStar} desc="Zero Delay Hiring" />
          <AcquisitionCard
            Img={AcquisitionTime}
            desc="Project Hiring Done Instantly"
          />{" "}
          <AcquisitionCard Img={AcquisitionCash} desc="No Revenue Losses" />
          <AcquisitionCard
            Img={AcquisitionSearch}
            desc="Trusted Hiring Platform"
          />{" "}
          <AcquisitionCard Img={AcquisitionHand} desc="Staffing Made Easy" />
          <AcquisitionCard
            Img={AcquisitionGlobe}
            desc="Access to Global Talent"
          />
        </div>
      </div>
    </div>
  );
};

export default TalentAcquisition;
