import React, { useEffect, useState } from "react";
import "./app.css";

const App = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    setScrollTop(scrollTop);
    setScrollHeight(scrollHeight);
    setClientHeight(clientHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initialize scrollbar on load
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getThumbWidth = () => {
    return (scrollTop / (scrollHeight - clientHeight)) * 100;
  };

  return (
    <div className="app">
      <div className="scrollbar">
        <div
          className="scroll-thumb"
          style={{ width: `${getThumbWidth()}%` }}
        ></div>
      </div>
      <div className="content">
        {/* Your content here */}
        <p>Scroll down to see the scrollbar in action...</p>
        <div
          style={{
            height: "100vh",
            background: "linear-gradient(to bottom, #ffcc00, #ff6699)",
          }}
        >
          {/* This is just a placeholder content to make the page scrollable */}
        </div>
      </div>
    </div>
  );
};

export default App;
