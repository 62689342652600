import React from "react";
import BlogComp from "../Components/BlogComp/BlogComp";

const Blog = () => {
  return (
    <div>
      <BlogComp />
    </div>
  );
};

export default Blog;
