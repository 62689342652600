import React, { useEffect } from "react";
import "./Mission.css";
import group from "../../../assests/group.svg";
import polo from "../../../assests/polo.svg";
import star from "../../../assests/star.svg";
import triangle from "../../../assests/triangle.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Mission = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="Mission sectionWidth">
        <div
          data-aos="zoom-in"
          data-aos-duration="1000"
          // data-aos-delay="300"
          className="MissionTop"
        >
          <p>
            At Hirein5, we believe the upcoming wave of organizations disrupting
            digital spaces will not be powered purely by technologies like AI or
            ML. Rather, the real differentiator will be old-school but
            unbeatable - human talent. And this requires, change in the existing
            hiring process.{" "}
          </p>
        </div>
        <div className="MissionFlex">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            // data-aos-delay="300"
            className="MissionFlexleft"
          >
            <p className="MissionPara ">
              Hirein5 was born from the need to reduce the hiring time of
              technology talent from 6-8 weeks to 5 hours in a clean,
              transparent, efficient manner. We witnessed several key projects
              fail due to not being able to hire talented resources in time,
              leading to losses in millions of dollars. Our mission is to solve
              the talent deficit and empower businesses to plan their
              transformation projects without the worry of hiring talent easily.{" "}
            </p>
            <img src={polo} alt="" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="300"
            className="MissionFlexRight"
          >
            <img src={group} alt="" />
          </div>
        </div>
        <div className="MissionFlex">
          <div
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="300"
            className="visionContent"
          >
            <h1 className="visionContentHead">Our Vision</h1>
            <p className="MissionPara ">
              To be a global leader in technology-enabled staffing solutions,
              bridging the talent gap by connecting top talent with
              transformative opportunities. We envision a world where the right
              job finds the right fit instantly, anywhere, thereby empowering
              organizations to achieve extraordinary growth with exceptional and
              diverse teams.
            </p>
            <img src={triangle} alt="" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="300"
            className="missionContent"
          >
            <h1 className="visionContentHead">Our Mission</h1>
            <p className="MissionPara">
              Simplify and accelerate hiring by leveraging cutting-edge
              technology, deep industry expertise, and a global network. We are
              committed to connecting talented professionals with leading
              organizations, ensuring a perfect match that drives success,
              innovation, inclusivity, and growth..
            </p>
            <div className="missionContentImg">
              <img src={star} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
