import React from "react";
import "./PricingHire.css";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";
import SectionHeading from "../../Reusable/SectionHeading/SectionHeading";

const PricingHire = () => {
  // const routeHandler = () => {
  //   window.location.replace("https://app.hirein5.com/#/clientsignup");
  // };

  const dispatch = useDispatch();

  const demoHandler = () => {
    dispatch(storeAction.isPopUpHander("demo"));
  };
  return (
    <div>
      <div className="pricingHire">
        <SectionHeading head1="Ready to Hirein5?" />
        <div className="pricingHireContent">
          {/* <input type="text" placeholder="Your Email" /> */}
          <a href="https://app.hirein5.com/#/clientregister" target="_blank">
            <button className="signButton marginRight30">Client Signup</button>
          </a>
          <button onClick={demoHandler} className="signButton">
            Book a Demo
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingHire;
