import React from "react";
import "./TryHirein5.css";
import star from "../../../assests/star.svg";
import polo from "../../../assests/polo.svg";
import pentagon from "../../../assests/pentagon.svg";
import triangle from "../../../assests/triangle.svg";
import semiCircle from "../../../assests/Semicircle.svg";
import square from "../../../assests/Square.svg";
import SectionHeading from "../../Reusable/SectionHeading/SectionHeading";

const TryHirein5 = () => {
  return (
    <div>
      <div className="tryHirein5Section sectionWidth">
        <div className="tryHirein5SectionHead">
          <SectionHeading
            head1="Why give Hirein5 a try?"
            head2=""
            desc1=""
            desc2=""
            desc3=""
            descMob=""
          />
        </div>
        <div className="tryHirein5Flex">
          <div className="tryHirein5Content bgBlack">
            <img src={star} alt="" />
            <div className="tryHirein5Text">
              <h1>Lightning Fast Talent Match</h1>
              <p>Connect with right talent and expedite your hiring process</p>
            </div>
          </div>
          <div className="tryHirein5Content BoxWidth bgBlue">
            <img src={polo} alt="" />
            <div className="tryHirein5Text">
              <h1>Discover Quality that Resonates</h1>
              <p>
                Engage with candidates who not only fit the role but also align
                with your values and aspirations
              </p>
            </div>
          </div>
        </div>
        <div className="tryHirein5Flex flexDirection">
          <div className="tryHirein5Content bgRed">
            <img src={pentagon} alt="" />
            <div className="tryHirein5Text">
              <h1>Tailored Hiring Blueprint</h1>
              <p>
                Personalize your hiring strategy with adaptable solutions
                tailored to your organizational needs
              </p>
            </div>
          </div>
          <div className="tryHirein5Content BoxWidth bgOrange">
            <img src={triangle} alt="" />
            <div className="tryHirein5Text">
              <h1>Embrace Global Talent</h1>
              <p>Dive into a world of global talent with a diverse skillsets</p>
            </div>
          </div>
        </div>
        <div className="tryHirein5Flex">
          <div className="tryHirein5Content bgGreen">
            <img src={semiCircle} alt="" />
            <div className="tryHirein5Text">
              <h1>Unlock Cost-Efficient Excellence</h1>
              <p>Experience superior ROI with Hirein5's smart solutions</p>
            </div>
          </div>
          <div className="tryHirein5Content BoxWidth bgBlack">
            <img src={square} alt="" />
            <div className="tryHirein5Text">
              <h1>Seamless, Simple, Superb</h1>
              <p>
                Navigate our intuitive platform effortlessly. Contact Us for
                Demo
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TryHirein5;
