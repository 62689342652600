import React from "react";
import JobSeekerBanner from "../Components/JobSeekerComp/JobSeekerBanner/JobSeekerBanner";
import HotJobs from "../Components/JobSeekerComp/HotJobs/HotJobs";
import CarrierSuccess from "../Components/JobSeekerComp/CarrierSuccess/CarrierSuccess";
import JobseekerBenefits from "../Components/JobSeekerComp/JobseekerBenefits/JobseekerBenefits";
import WhyChooseUs from "../Components/Reusable/WhyChooseUs/WhyChooseUs";

const JobSeeker = () => {
  return (
    <div>
      <JobSeekerBanner />
      <div className="JobSeekerSection2">
        <HotJobs />
        <CarrierSuccess />
      </div>
      <div className="JobSeekerSection3">
        <JobseekerBenefits />
      </div>
      <WhyChooseUs />
    </div>
  );
};

export default JobSeeker;
