import React, { useEffect } from "react";
import "./OurCulture.css";
import cultureImg from "../../../assests/culture.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const OurCulture = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <div className="ourCulture">
        <h1 data-aos-once="true" data-aos="fade-right" data-aos-delay="300">
          Our Guiding Principles
        </h1>
        <div className="ourCultureFlex">
          <div className="ourCultureFlexLeft">
            <div
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-duration="1000"
              data-aos-delay="500"
              className="ourCultureFlexContent"
            >
              <div className="ourCultureFlexContentNumber">
                <h2>01</h2>
              </div>
              <div className="ourCultureFlexContentDesc">
                <h3>Action</h3>
                <p>
                  We have a bias for action and outcomes. We act to achieve our
                  goals, our commitments and help our clients succeed.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-duration="1000"
              data-aos-delay="700"
              className="ourCultureFlexContent"
            >
              <div className="ourCultureFlexContentNumber">
                <h2>02</h2>
              </div>
              <div className="ourCultureFlexContentDesc">
                <h3>Boundarylessness</h3>
                <p>
                  We go beyond limitations to ensure that everyone in our
                  ecosystem succeeds. We work in a global environment that
                  fosters creativity, helpfulness and growth.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-duration="1000"
              data-aos-delay="900"
              className="ourCultureFlexContent"
            >
              <div className="ourCultureFlexContentNumber">
                <h2>03</h2>
              </div>
              <div className="ourCultureFlexContentDesc">
                <h3>Fail Forward</h3>
                <p>
                  We fail fast, we fail moving forward, We learn fast, and act
                  faster. We try, experiment and in doing so, we sometimes fail,
                  and that is okay!
                </p>
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-duration="1000"
              data-aos-delay="1100"
              className="ourCultureFlexContent"
            >
              <div className="ourCultureFlexContentNumber">
                <h2>04</h2>
              </div>
              <div className="ourCultureFlexContentDesc">
                <h3>Respect</h3>
                <p>
                  We respect ourselves, our colleagues, our vendors, our
                  clients, and we expect the same in return. We respect
                  diversity, inclusivity and have a passionate commitment to
                  saving planet earth.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-duration="1000"
              data-aos-delay="1300"
              className="ourCultureFlexContent"
            >
              <div className="ourCultureFlexContentNumber">
                <h2>05</h2>
              </div>
              <div className="ourCultureFlexContentDesc">
                <h3>Transparency</h3>
                <p>
                  We are fair, and transparent in how we interact and deal with
                  everyone within the company and the world outside
                </p>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-once="true"
            data-aos-duration="1000"
            data-aos-delay="500"
            className="ourCultureFlexRight"
          >
            <img src={cultureImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCulture;
