import React from "react";
import "../Components/AboutComp/AboutBanner/AboutBanner.css";
import AboutBanner from "../Components/AboutComp/AboutBanner/AboutBanner";
import Mission from "../Components/AboutComp/Mission/Mission";
import OurCulture from "../Components/AboutComp/OurCulture/OurCulture";
import WhyChooseUs from "../Components/Reusable/WhyChooseUs/WhyChooseUs";
import ConsiderHire from "../Components/AboutComp/ConsiderHire/ConsiderHire";

const About = () => {
  return (
    <div>
      <div className="AboutContentWhite">
        <AboutBanner />
        <Mission />
        <OurCulture />
      </div>
      <div className="AboutContentOrange">
        <ConsiderHire />
      </div>
      <div className="AboutContentblue">
        <WhyChooseUs />
      </div>
    </div>
  );
};

export default About;
