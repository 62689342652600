import React from "react";
import "./PricingBanner.css";
import pricingLeftBanner from "../../../assests/PricingBannerLeft.svg";
import pricingRightBanner from "../../../assests/PricingBannerRight.svg";
import { useNavigate } from "react-router-dom";

const PricingBanner = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="pricingBanner">
        <img
          className="pricingLeftBanner webHandler"
          src={pricingLeftBanner}
          alt=""
        />
        <div className="pricingBannerContent">
          <h1>Pricing & Plans</h1>
          <p className="webHandler">
            We offer flexible pricing plans for organizations of varying sizes,
            needs, and locations Need help in selecting a plan?
          </p>
          <button
            onClick={(e) => navigate("/contact")}
            className="signButton webHandler"
          >
            Contact Us
          </button>
          <p className="mobileHandler">
            We offer flexible pricing plans for organizations of varying sizes,
            needs, and locations
            <br /> Need help in selecting a plan?
            <span onClick={(e) => navigate("/contact")}>Contact Us</span>
          </p>
        </div>
        <img
          className="pricingRightBanner webHandler"
          src={pricingRightBanner}
          alt=""
        />
      </div>
    </div>
  );
};

export default PricingBanner;
