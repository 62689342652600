import React from "react";
import "./ResourceBanner.css";
import resourceBannerImgLeft from "../../../assests/ResourceBannerLeft.svg";
import resourceBannerImgRight from "../../../assests/ResourceBannerRight.svg";

const ResourceBanner = () => {
  return (
    <div>
      <div className="resourceBanner">
        <div className="resourceBannerFlex">
          <div className="resourceBannerFlexLeft webHandler">
            <img src={resourceBannerImgLeft} alt="" />
          </div>
          <div className="resourceBannerFlexCenter">
            <h1>
              <span>Hirein5</span>is streamlining
              <br /> talent discovery!
            </h1>
            <p>Changing the way most of the hiring is done, till now!</p>
          </div>
          <div className="resourceBannerFlexRight webHandler">
            <img src={resourceBannerImgRight} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceBanner;
