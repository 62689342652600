import React from "react";
import "./JobCard.css";
import { IoLocationOutline } from "react-icons/io5";
import { LuClock } from "react-icons/lu";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";

const JobCard = (props) => {
  const dispatch = useDispatch();
  const ApplyHandler = (e) => {
    dispatch(storeAction.isPopUpHander("applynow"));
    props.sethotjob(e);
  };
  return (
    <div>
      <div className="jobCard">
        <div className="jobCardDetails">
          <h2>{props.Team}</h2>
          <h1>{props.Role}</h1>
        </div>
        <div className="jobCardSkills">
          {props.Skills.map((data) => {
            return <p>{data.skillName}</p>;
          })}
          {/* <p>Java EEE</p>
          <p>Javascript</p>
          <p>Android</p>
          <p>React</p>
          <p>Backend development</p> */}
        </div>
        <div className="jobCardDesc">
          <div className="jobCardDescLocation">
            <IoLocationOutline className="jobCardDescLocationIcon" />
            <h5>{props.Location}</h5>
          </div>
          <div className="jobCardDescLocation">
            <LuClock className="jobCardTime" />
            <h5>{props.Available}</h5>
          </div>
        </div>
        <div className="jobCardButton">
          {/* <a href="https://stage.hirein5.com/#/clientsignup" target="_blank"> */}
          <button
            onClick={() => {
              ApplyHandler(props.Team);
            }}
            className="signButton"
          >
            Share your Resume
          </button>
          {/* </a> */}
        </div>
      </div>
    </div>
  );
};

export default JobCard;
