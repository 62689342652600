/* eslint-disable eqeqeq */
import React, { useState } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import FaqCard from "../FaqCard/FaqCard";
import { useLocation } from "react-router-dom";

const Faq = () => {
  const location = useLocation();
  const [isShow, setIsShow] = useState("6");
  const faqHandler = (e) => {
    setIsShow(e.target.id);
  };
  return (
    <div>
      <div className="faq">
        <div className="faqHead mainbody">
          <SectionHeading
            head1="Frequently Asked Questions"
            head2=""
            desc1=""
            desc2=""
            desc3=""
          />
        </div>
        {location.pathname == "/pricing" ? (
          <div className="faqBody">
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="6"
              numClass="numberNone"
              head="Why do you charge an upfront fee during sign up?"
              headClass="headBold"
              desc="Our unique set up and platform provides access to pre vetted talent who can be on-boarded in a matter of hours. We believe that this creates significant value for businesses, and saves significant time and cost for the recruiters and the business. Our on-boarding fee is nominal in comparison to the value it creates."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="7"
              numClass="numberNone"
              head="Why do you charge an Annual or Monthly access fee over and above the initial set up fee?"
              headClass="headBold"
              desc="We add new talent and resources each month on our platform and would like our clients to benefit from the continuous improvements that we are making. Our subscription fee ensures that you continue to have access to new skills and talent."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="8"
              numClass="numberNone"
              head="Can you waive the initial set up fees in lieu of the monthly subscription?"
              headClass="headBold"
              desc="The value that Hirein5 generates is of significance and saves time, effort and money, while accessing amazing talent at the click of a button. Our pricing is well placed to provide continuous value for the team."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="9"
              numClass="numberNone"
              head="Are the resource prices fixed?"
              headClass="headBold"
              desc="We carefully evaluate our talent’s skills and fix prices accordingly. Our talent is of high quality and can add significant value to any project. Our prices are therefore transparent and published on the platform against each candidate."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="10"
              numClass="numberNone"
              head="Where can I find all the contracts that I sign with Hirein5?"
              headClass="headBold"
              desc="Every contract that you sign is available under your account on the platform for ease of access and reference."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="11"
              numClass="numberNone"
              head="Is Hirein5 suitable for businesses of all sizes?"
              headClass="headBold"
              desc="Yes, Hirein5 is designed to cater to businesses of all sizes, including startups, small and medium-sized enterprises (SMEs), as well as large corporations. Our platform is scalable and adaptable to the needs of companies regardless of their size."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="12"
              numClass="numberNone"
              head="Are there any additional fees or hidden costs that I should be aware of?"
              headClass="headBold"
              desc="Transparency is important to us, and we strive to ensure that our pricing is clear and upfront. There are no hidden costs or surprise fees – what you see is what you get."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="13"
              numClass="numberNone"
              head="What happens if I need to scale up or down my team's resources?"
              headClass="headBold"
              desc="Our offering is flexible to accommodate your changing needs. You can easily adjust your team size by adding or removing resources as provisioned in the contract, ensuring you have the right talent for your team at all times."
            />
          </div>
        ) : location.pathname == "/" ? (
          <div className="faqBody">
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="6"
              numClass="numberNone"
              head="What is Hirein5?"
              headClass="headBold"
              desc="Hirein5 is a platform that has integrated all stages of hiring from Search to Offer in one platform, thereby making it easy and efficient for the recruiters and employers to hire talent."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="7"
              numClass="numberNone"
              head="How does Hirein5 Work?"
              headClass="headBold"
              desc="Hirein5 revolutionises C2C hiring, slashing weeks of recruitment anxiety and costs. Our platform stands out with pre-vetted resources, not just technically assessed but also evaluated for personality, aptitude and background verification, ensuring swift, confident hiring decisions. Clients can directly evaluate candidates through their video resumes, providing a holistic view of their qualifications and fitment for the role."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="8"
              numClass="numberNone"
              head="How is Hirein5 different from other platforms? "
              headClass="headBold"
              desc="Hirein5 is not a job or an interview portal. It is an integrated end to end hiring platform for C2C engagements. Hirein5 is investing heavily in automating the process even further using reliable AI components."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="9"
              numClass="numberNone"
              head="Can Hirein5 really fulfil the requirement in under 5 hours?"
              headClass="headBold"
              desc="Process efficiency by the hiring team is the key in meeting the timelines. Hirein5 has enabled a standard hiring process laid down by most companies, from online selection, assessment, interviews and contractual signatures."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="10"
              numClass="numberNone"
              head="What happens if the resource we hire does meet our requirements?"
              headClass="headBold"
              desc="The contract has clear provisions for such rare occurrences where there may be a mismatch between the technical requirements and the resources who have been signed up."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="11"
              numClass="numberNone"
              head="Is Hirein5 suitable for businesses of all sizes?"
              headClass="headBold"
              desc="Yes, Hirein5 is designed to cater to businesses of all sizes, including startups, small and medium-sized enterprises (SMEs), as well as large corporations. Our platform is scalable and adaptable to the needs of companies regardless of their size."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="12"
              numClass="numberNone"
              head="What industries or sectors does Hirein5 specialise in?"
              headClass="headBold"
              desc="Hirein5 is versatile and can be utilised across various industries , sectors and domains."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="13"
              numClass="numberNone"
              head="Can clients track the progress of their hiring process on Hirein5?"
              headClass="headBold"
              desc="Yes, clients have full visibility into the progress of their hiring process on Hirein5. Our platform provides real-time tracking and monitoring capabilities, allowing clients to stay informed about the status of each stage, from candidate sourcing to offer acceptance."
            />
          </div>
        ) :  (
          <div className="faqBody">
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="6"
              numClass="numberNone"
              head="What is Hirein5?"
              headClass="headBold"
              desc="Hirein5 is a platform that has integrated all stages of hiring from Search to Offer in one platform, thereby making it easy and efficient for the recruiters and employers to hire talent."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="7"
              numClass="numberNone"
              head="How does Hirein5 Work?"
              headClass="headBold"
              desc="Hirein5 revolutionises C2C hiring, slashing weeks of recruitment anxiety and costs. Our platform stands out with pre-vetted resources, not just technically assessed but also evaluated for personality, aptitude and background verification, ensuring swift, confident hiring decisions. Clients can directly evaluate candidates through their video resumes, providing a holistic view of their qualifications and fitment for the role."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="8"
              numClass="numberNone"
              head="How is Hirein5 different from other platforms? "
              headClass="headBold"
              desc="Hirein5 is not a job or an interview portal. It is an integrated end to end hiring platform for C2C engagements. Hirein5 is investing heavily in automating the process even further using reliable AI components."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="9"
              numClass="numberNone"
              head="Can Hirein5 really fulfil the requirement in under 5 hours?"
              headClass="headBold"
              desc="Process efficiency by the hiring team is the key in meeting the timelines. Hirein5 has enabled a standard hiring process laid down by most companies, from online selection, assessment, interviews and contractual signatures."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="10"
              numClass="numberNone"
              head="What happens if the resource we hire does meet our requirements?"
              headClass="headBold"
              desc="The contract has clear provisions for such rare occurrences where there may be a mismatch between the technical requirements and the resources who have been signed up."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="11"
              numClass="numberNone"
              head="Is Hirein5 suitable for businesses of all sizes?"
              headClass="headBold"
              desc="Yes, Hirein5 is designed to cater to businesses of all sizes, including startups, small and medium-sized enterprises (SMEs), as well as large corporations. Our platform is scalable and adaptable to the needs of companies regardless of their size."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="12"
              numClass="numberNone"
              head="What industries or sectors does Hirein5 specialise in?"
              headClass="headBold"
              desc="Hirein5 is versatile and can be utilised across various industries , sectors and domains."
            />
            <FaqCard
              fun={faqHandler}
              state={isShow}
              setState={setIsShow}
              number="13"
              numClass="numberNone"
              head="Can clients track the progress of their hiring process on Hirein5?"
              headClass="headBold"
              desc="Yes, clients have full visibility into the progress of their hiring process on Hirein5. Our platform provides real-time tracking and monitoring capabilities, allowing clients to stay informed about the status of each stage, from candidate sourcing to offer acceptance."
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Faq;
