import React from "react";
import "./AcquisitionCard.css";

const AcquisitionCard = (props) => {
  return (
    <div>
      <div className="acquisitionCard">
        <img src={props.Img} alt="" />
        <p>{props.desc}</p>
      </div>
    </div>
  );
};

export default AcquisitionCard;
