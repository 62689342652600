import React from "react";
import "../Components/ResourcesComp/ResourceBanner/ResourceBanner.css";
import ResourceBanner from "../Components/ResourcesComp/ResourceBanner/ResourceBanner";
import ResourceSearch from "../Components/ResourcesComp/ResourceSearch/ResourceSearch";
import WhyChooseUs from "../Components/Reusable/WhyChooseUs/WhyChooseUs";

const Resources = () => {
  return (
    <div>
      <ResourceBanner />
      <div className="searchResource">
        <ResourceSearch />
      </div>
      <WhyChooseUs />
    </div>
  );
};

export default Resources;
