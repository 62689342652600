// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pricing {
    display: flex;
    align-items: center;
    justify-content: center;

}

.features {
    width: 100%;
    text-align: start;
    padding: 20px;
}

.features p {
    font-size: 18px;
    font-weight: 400;
    font-family: var(--primaryFont);
    line-height: 25px;
}

.plan p {
    font-size: 18px;
    font-weight: 400;
    font-family: var(--primaryFont);
    line-height: 25px;
}

.plan {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media(max-width:1200px){
    .features{
        padding: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/PricingCard/PricingCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;AAE3B;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,+BAA+B;IAC/B,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".Pricing {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n}\n\n.features {\n    width: 100%;\n    text-align: start;\n    padding: 20px;\n}\n\n.features p {\n    font-size: 18px;\n    font-weight: 400;\n    font-family: var(--primaryFont);\n    line-height: 25px;\n}\n\n.plan p {\n    font-size: 18px;\n    font-weight: 400;\n    font-family: var(--primaryFont);\n    line-height: 25px;\n}\n\n.plan {\n    width: 90%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n@media(max-width:1200px){\n    .features{\n        padding: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
