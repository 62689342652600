// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carrierSuccessConatiner {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
}

.carrierSuccessConatinerCard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.carrierSuccessConatinerCardTab {
    display: none;
}

@media(max-width: 1200px){
    .carrierSuccessConatinerCardTab {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .carrierSuccessConatiner{
        width: 68%;
    }
    .carrierSuccessConatinerCard{
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .carrierSuccessConatinerCard {
        display: block;
        display: grid !important;
        grid-template-columns: repeat(1, 1fr);
    }

    .carrierSuccessConatiner {
        width: 91%;
    }
    .carrierSuccessConatinerCardTab{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/JobSeekerComp/CarrierSuccess/CarrierSuccess.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;QACb,qCAAqC;QACrC,SAAS;QACT,gBAAgB;QAChB,mBAAmB;IACvB;IACA;QACI,UAAU;IACd;IACA;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,cAAc;QACd,wBAAwB;QACxB,qCAAqC;IACzC;;IAEA;QACI,UAAU;IACd;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".carrierSuccessConatiner {\n    width: 80%;\n    margin-left: auto;\n    margin-right: auto;\n    padding-bottom: 100px;\n}\n\n.carrierSuccessConatinerCard {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 25px;\n    margin-top: 50px;\n    margin-bottom: 50px;\n}\n\n.carrierSuccessConatinerCardTab {\n    display: none;\n}\n\n@media(max-width: 1200px){\n    .carrierSuccessConatinerCardTab {\n        display: grid;\n        grid-template-columns: repeat(1, 1fr);\n        gap: 25px;\n        margin-top: 50px;\n        margin-bottom: 50px;\n    }\n    .carrierSuccessConatiner{\n        width: 68%;\n    }\n    .carrierSuccessConatinerCard{\n        display: none;\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    .carrierSuccessConatinerCard {\n        display: block;\n        display: grid !important;\n        grid-template-columns: repeat(1, 1fr);\n    }\n\n    .carrierSuccessConatiner {\n        width: 91%;\n    }\n    .carrierSuccessConatinerCardTab{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
