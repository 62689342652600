import React from "react";
import ContactBanner from "../Components/ContactComp/ContactBanner/ContactBanner";
import ContactForm from "../Components/ContactComp/ContactForm/ContactForm";
// import ContactMode from "../Components/ContactComp/ContactMode/ContactMode";
// import WhyChooseUs from "../Components/Reusable/WhyChooseUs/WhyChooseUs";

const Contact = () => {
  return (
    <div className="contactPage">
      <ContactBanner />
      <ContactForm />
      {/* <ContactMode /> */}
      {/* <WhyChooseUs /> */}
    </div>
  );
};

export default Contact;
