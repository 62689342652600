// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TalentAcquisition {
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: var(--fourthColor);
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    position: relative;
    z-index: -1;
    margin-top: -100px;
    margin-bottom: 80px;
}

.acquisitionContainer {
    width: 70% !important;
    margin-top: 70px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

@media only screen and (max-width: 600px) {
    .acquisitionContainer {
        width: 90% !important;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .TalentAcquisition {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        z-index: -1;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/HowItWorkComp/TalentAcquisition/TalentAcquisition.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,oCAAoC;IACpC,gCAAgC;IAChC,iCAAiC;IACjC,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,aAAa;IACb,qCAAqC;IACrC,SAAS;AACb;;AAEA;IACI;QACI,qBAAqB;QACrB,qCAAqC;QACrC,SAAS;IACb;;IAEA;QACI,+BAA+B;QAC/B,gCAAgC;QAChC,WAAW;IACf;AACJ","sourcesContent":[".TalentAcquisition {\n    padding-top: 150px;\n    padding-bottom: 150px;\n    background-color: var(--fourthColor);\n    border-bottom-left-radius: 100px;\n    border-bottom-right-radius: 100px;\n    position: relative;\n    z-index: -1;\n    margin-top: -100px;\n    margin-bottom: 80px;\n}\n\n.acquisitionContainer {\n    width: 70% !important;\n    margin-top: 70px;\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n}\n\n@media only screen and (max-width: 600px) {\n    .acquisitionContainer {\n        width: 90% !important;\n        grid-template-columns: repeat(2, 1fr);\n        gap: 15px;\n    }\n\n    .TalentAcquisition {\n        border-bottom-left-radius: 24px;\n        border-bottom-right-radius: 24px;\n        z-index: -1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
