import React from "react";
import "./FaqCard.css";

const FaqCard = (props) => {
  // const [isShow, setIsShow] = useState("");
  // const faqHandler = (e) => {
  //   setIsShow(e.target.id);
  // };
  return (
    <div>
      <div
        className={props.state === props.number ? "faqCardActive" : "faqCard"}
        // className="faqCard"
      >
        <div className="faqCardHead">
          <div className="faqCardHeadLeft">
            <h1 className={props.numClass}>{props.number}</h1>
            <h2 className={props.headClass}>{props.head}</h2>
          </div>
          <div
            onClick={props.fun}
            id={props.number}
            className="faqCardHeadRight"
          >
            {props.state === props.number ? (
              <>
                <p>-</p>
              </>
            ) : (
              <>
                <p id={props.number}>+</p>
              </>
            )}
          </div>
        </div>
        {/* {props.state === props.number && ( */}
        <div
          // data-aos="fade-up"
          // data-aos-once="true"
          className={
            props.state === props.number
              ? "faqCardContentActive"
              : "faqCardContent"
          }
          
        >
          <p>{props.desc}</p>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default FaqCard;
