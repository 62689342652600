import React from "react";
import PricingBanner from "../Components/PricingComp/PricingBanner/PricingBanner";
import PricingPlan from "../Components/PricingComp/PricingPlans/PricingPlan";
import PricingHire from "../Components/PricingComp/PricingHire/PricingHire";
import TryHirein5 from "../Components/PricingComp/TryHirein5/TryHirein5";
import Faq from "../Components/Reusable/Faq/Faq";
import WhyChooseUs from "../Components/Reusable/WhyChooseUs/WhyChooseUs";

const Pricing = () => {
  return (
    <div>
      <PricingBanner />
      <PricingPlan />
      <PricingHire />
      <div className="hiringSection">
        <TryHirein5 />
      </div>
      <Faq />
      <WhyChooseUs />
    </div>
  );
};

export default Pricing;
