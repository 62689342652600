import React from "react";
import HomeBanner from "../Components/HomePageComp/HomeBannerSection/HomeBanner";
import HiringSection from "../Components/HomePageComp/HiringSection/HiringSection";

const Home = () => {
  return (
    <div>
      <HomeBanner />
      <HiringSection />
    </div>
  );
};

export default Home;
