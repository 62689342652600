// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricingCardMob {
    text-align: left;
}

.pricingCardMob h3 {
    font-family: var(--primaryFont);
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
}

.planMob {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.planMobContent {
    width: 100%;
    text-align: center !important;
    border: 1.5px solid var(--borderColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
}

.planBgc {
    background-color: var(--secondaryColor);
}

.planMobContent p {
    font-size: 12px;
    font-family: var(--primaryFont);
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/PricingCardMob/PricingCardMob.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B,sCAAsC;IACtC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,eAAe;IACf,+BAA+B;IAC/B,gBAAgB;AACpB","sourcesContent":[".pricingCardMob {\n    text-align: left;\n}\n\n.pricingCardMob h3 {\n    font-family: var(--primaryFont);\n    font-size: 14px;\n    font-weight: 500;\n    margin-top: 15px;\n    margin-bottom: 15px;\n}\n\n.planMob {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.planMobContent {\n    width: 100%;\n    text-align: center !important;\n    border: 1.5px solid var(--borderColor);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 10px 0px;\n}\n\n.planBgc {\n    background-color: var(--secondaryColor);\n}\n\n.planMobContent p {\n    font-size: 12px;\n    font-family: var(--primaryFont);\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
