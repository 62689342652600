import React from "react";
import "./ContactBanner.css";
import contactLeft from "../../../assests/PricingBannerLeft.svg";
import contactRight from "../../../assests/PricingBannerRight.svg";

const ContactBanner = () => {
  return (
    <div>
      <div className="contactBanner">
        <img className="webHandler" src={contactLeft} alt="" />
        <div className="contactBannerContent">
          <h1>We’d love to hear from you</h1>
        </div>
        <img className="webHandler" src={contactRight} alt="" />
      </div>
    </div>
  );
};

export default ContactBanner;
