// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carrierSuccessCard {
    min-height: 330px;
    width: 100%;
    text-align: start;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.carrierSuccessCard img {
    height: 50px;
    margin-bottom: 15px;
}

.carrierSuccessCard h1 {
    font-family: var(--secondaryFont);
    color: var(--white);
    font-size: 24px;
    font-weight: 500;
}

.carrierSuccessCard p {
    font-family: var(--secondaryFont);
    color: var(--white);
    font-size: 20px;
    font-weight: 400;
}

.carrierSuccessCardOrange {
    background-color: var(--fourthColor);
}

.carrierSuccessCardBlack {
    background-color: var(--primaryTextColor);
}

.carrierSuccessCardGreen {
    background-color: var(--cardHeadColor);
}

@media(max-width: 1200px){
    .carrierSuccessCard {
        min-height: 250px;
    }
}

@media only screen and (max-width: 600px) {
    .carrierSuccessCard {
        min-height: 250px;
    }

    .carrierSuccessCard h1 {
        font-size: 20px;
    }

    .carrierSuccessCard p {
        font-size: 16px;
    }

    .carrierSuccessCard img {
        height: 40px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/CarrierSuccessCard/CarrierSuccessCard.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".carrierSuccessCard {\n    min-height: 330px;\n    width: 100%;\n    text-align: start;\n    padding: 30px;\n    border-radius: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: space-between;\n}\n\n.carrierSuccessCard img {\n    height: 50px;\n    margin-bottom: 15px;\n}\n\n.carrierSuccessCard h1 {\n    font-family: var(--secondaryFont);\n    color: var(--white);\n    font-size: 24px;\n    font-weight: 500;\n}\n\n.carrierSuccessCard p {\n    font-family: var(--secondaryFont);\n    color: var(--white);\n    font-size: 20px;\n    font-weight: 400;\n}\n\n.carrierSuccessCardOrange {\n    background-color: var(--fourthColor);\n}\n\n.carrierSuccessCardBlack {\n    background-color: var(--primaryTextColor);\n}\n\n.carrierSuccessCardGreen {\n    background-color: var(--cardHeadColor);\n}\n\n@media(max-width: 1200px){\n    .carrierSuccessCard {\n        min-height: 250px;\n    }\n}\n\n@media only screen and (max-width: 600px) {\n    .carrierSuccessCard {\n        min-height: 250px;\n    }\n\n    .carrierSuccessCard h1 {\n        font-size: 20px;\n    }\n\n    .carrierSuccessCard p {\n        font-size: 16px;\n    }\n\n    .carrierSuccessCard img {\n        height: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
