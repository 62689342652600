/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./WhyChooseUs.css";
import reqBgImg from "../../../assests/reqBg.svg";
import reqBgImgMob from "../../../assests/reqImgBgMob.svg";
import { useLocation } from "react-router-dom";

const WhyChooseUs = () => {
  const location = useLocation();
  return (
    <div>
      <div className="requirements">
        <div className="requirementsContent sectionWidth mainbody">
          {location.pathname.slice(1) == "talent" ? (
            <>
              <h1>Work with Top Global Companies</h1>
              <h1>on some amazing projects</h1>
              <p>
                Join a talented tribe of developers and tech resources from
                across the world. Grow as you learn and work in a global
                culture.
              </p>
            </>
          ) : (
            <>
              <h1>From Requirements to</h1>
              <h1>Onboarding in 5 hours</h1>
              <p>
                Curated shortlists of perfect-match candidates rapidly
                delivered. Professionals committed and excited to start
                immediately.
              </p>
            </>
          )}
          <div className="requirementButton">
            {location.pathname.slice(1) == "talent" ? (
              <button className="loginButtonReq">
                <a
                  href="https://app.hirein5.com/#/candidateregister"
                  target="_blank"
                >
                  Apply Now
                </a>
              </button>
            ) : (
              <button className="loginButtonReq">
                <a
                  href="https://app.hirein5.com/#/clientregister"
                  target="_blank"
                >
                  Sign Up
                </a>
              </button>
            )}

            {/* <button className="signButtonReq">
              <a href="https://app.hirein5.com/#/login" target="_blank">
                Login
              </a>
            </button> */}
          </div>
          <img className="reqBgImg webHandler" src={reqBgImg} alt="" />
        </div>
        <img className="reqBgImgMob mobileHandler" src={reqBgImgMob} alt="" />
      </div>
    </div>
  );
};

export default WhyChooseUs;
