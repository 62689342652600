import { configureStore, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const StoreSlice = createSlice({
  name: "HireIn5",
  initialState: {
    cartitem: null,
    isPopUp: "",
  },
  reducers: {
    cartitemHandler(state, payload) {
      console.log(payload, "payload");
      state.cartitem = payload.payload.cartitem;
    },
    isPopUpHander(state, action) {
      state.isPopUp = action.payload;
    },
  },
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cartitem"],
};

const persistedReducer = persistReducer(persistConfig, StoreSlice.reducer);
const Store = configureStore({
  reducer: persistedReducer,
});
export const storeAction = StoreSlice.actions;

const persistor = persistStore(Store);

export { Store, persistor };
