/* eslint-disable no-dupe-keys */
import React from "react";
import "./JobseekerBenefits.css";
import healthImg from "../../../assests/JobseekerHealth.svg";
import financial from "../../../assests/JobseekerFinancial.svg";
import professional from "../../../assests/JobseekerProfessional.svg";
import international from "../../../assests/JobseekerInternational.svg";
import additional from "../../../assests/JobseekerAdditional.svg";
import SectionHeading from "../../Reusable/SectionHeading/SectionHeading";
import { FiCheck } from "react-icons/fi";

const JobseekerBenefits = () => {
  const healthDesc = [
    {
      head: "Generous Leave Programs",
      iconClass: "jobseekerBenefitsIcon iconColorBlue",
      desc: "Comprehensive leave program to live life, have fun, and balance work-life choices",
      // desc1:
      //   "Comprehensive leave program to live life, have fun, and achieve perfect",
      // desc2: "work-life balance",
      descMob:
        "Comprehensive leave program to live life, have fun, and balance work-life choices",
    },
    {
      head: "Special Occasion Leaves",
      iconClass: "jobseekerBenefitsIcon ",
      desc: "Paid leave to celebrate the special occasions with your loved one - Birthdays, Anniversary, Kids Birthdays",
      // desc1:
      //   "Generous paid time off and holiday policies to help you recharge and spend",
      // desc2: "time with loved ones",
      descMob:
        "Paid leave to celebrate the special occasions with your loved one - Birthdays, Anniversary, Kids Birthdays",
    },
    {
      head: "Parental Leave",
      iconClass: "jobseekerBenefitsIcon ",
      desc: "Supportive paternity leave policies to help new dads transition into their new roles",
      // desc1:
      //   "Supportive parental leave policies to help new parents transition into their",
      // desc2: "new roles",
      descMob:
        "Supportive paternity leave policies to help new dads transition into their new roles",
    },
  ];
  const financialDesc = [
    {
      iconClass: "jobseekerBenefitsIcon ",
      head: "Competitive Compensation",
      iconClass: "jobseekerBenefitsIcon",
      desc: "A competitive compensation structure that reflects your skills, experience, and contributions, along with annual performance reviews and perks.",
      // desc1:
      //   "A competitive salary structure that reflects your skills, experience, and",
      // desc2: "contributions",
      descMob:
        "A competitive compensation structure that reflects your skills, experience, and contributions, along with annual performance reviews and perks.",
    },
    {
      iconClass: "jobseekerBenefitsIcon",
      head: "ESOPs",
      iconClass: "jobseekerBenefitsIcon iconColorRed",
      desc: "Not just an employee but a shareholder of the company. Stock options may be offered based on the company's growth and key employee milestones, including your performance and tenure with the company.  ",
      // desc1:
      //   " Eligibility for stock options, aligning your success with the company's",
      // desc2: "growth and key employee milestones",
      descMob:
        "Not just an employee but a shareholder of the company. Stock options may be offered based on the company's growth and key employee milestones, including your performance and tenure with the company. ",
    },
    {
      iconClass: "jobseekerBenefitsIcon",
      head: "Generous Allowances: ",
      iconClass: "jobseekerBenefitsIcon iconColorRed",
      desc: "Communication allowances, Travel allowances, breakthrough rewards; aligned with company policies",
      // desc1:
      //   " Eligibility for stock options, aligning your success with the company's",
      // desc2: "growth and key employee milestones",
      descMob:
        "Communication allowances, Travel allowances, breakthrough rewards; aligned with company policies",
    },
  ];
  const internationalDesc = [
    {
      iconClass: "jobseekerBenefitsIcon ",
      head: "International Assignments",
      iconClass: "jobseekerBenefitsIcon",
      desc: "At Hirein5, we work with global clients who welcome resources to be based out of their offices. Get placed at Hirein5 offices internationally.",
      // desc1: "Work on meaningful projects and with some of the best minds",
      // desc2: "",
      descMob:
        "At Hirein5, we work with global clients who welcome resources to be based out of their offices. Get placed at Hirein5 offices internationally.",
    },
    {
      iconClass: "jobseekerBenefitsIcon ",
      head: "Working with a global cohort",
      iconClass: "jobseekerBenefitsIcon",
      desc: "You will be a part of an international network of professionals and not just limited to working on one assignment, to learn and grow with the best",
      // desc1:
      //   "Receive comprehensive support for relocation, including visa sponsorship",
      // desc2: " and cultural orientation.",
      descMob:
        "You will be a part of an international network of professionals and not just limited to working on one assignment, to learn and grow with the best",
    },
    {
      iconClass: "jobseekerBenefitsIcon ",
      head: "Relocation",
      iconClass: "jobseekerBenefitsIcon",
      desc: "Get placed in other geographical locations, based on client demand and your individual preferences. Support provided by the company towards international relocation. ",
      // desc1:
      //   "Continued Relocation support, mentorship, and opportunities",
      // desc2: "for advancement while on international assignment",
      descMob:
        "Get placed in other geographical locations, based on client demand and your individual preferences. Support provided by the company towards international relocation. ",
    },
  ];

  const professionalDesc = [
    {
      iconClass: "jobseekerBenefitsIcon",
      head: "Training and Development",
      iconClass: "jobseekerBenefitsIcon",
      desc: "We believe that the company will grow only if our talent grows. Dedicated time for development of new skills and enhancement of existing skills.",
      // desc1:
      //   "Dedicated training and development allowance to enhance your skills and",
      // desc2: "career growth",
      descMob:
        "We believe that the company will grow only if our talent grows. Dedicated time for development of new skills and enhancement of existing skills.",
    },
    {
      iconClass: "jobseekerBenefitsIcon",
      head: "Professional Development Allowance",
      iconClass: "jobseekerBenefitsIcon",
      desc: "Annual budget allocated for each employee towards enhancing their skills and Relocation via professional certifications.",
      // desc1:
      //   "Opportunities for mentorship and career guidance from experienced",
      // desc2: "professionals in the industry.",
      descMob:
        "Annual budget allocated for each employee towards enhancing their skills and career development via professional certifications.",
    },
    {
      iconClass: "jobseekerBenefitsIcon",
      head: "Global Mentorship Programs",
      iconClass: "jobseekerBenefitsIcon",
      desc: "Opportunities for mentorship from leaders within the company and experienced professionals in the industry.",
      // desc1:
      //   "Financial assistance for furthering your education and professional",
      // desc2: "certifications",
      descMob:
        "Opportunities for mentorship from leaders within the company and experienced professionals in the industry.",
    },
  ];

  const additionalDesc = [
    {
      iconClass: "jobseekerBenefitsIcon",
      head: "Team Events and Retreats",
      iconClass: "jobseekerBenefitsIcon",
      desc: "Team-building events, outings, and retreats to foster camaraderie and collaboration, beyond the office rituals.",
      // desc1:
      //   "Regular team-building events, outings, and retreats to foster camaraderie",
      // desc2: "and collaboration",
      descMob:
        "Team-building events, outings, and retreats to foster camaraderie and collaboration, beyond the office rituals.",
    },
    {
      iconClass: "jobseekerBenefitsIcon",
      head: "Innovation Allowance",
      iconClass: "jobseekerBenefitsIcon",
      desc: "Got great ideas? Work on your ideas while getting support from the company to take your idea further.",
      // desc1: "Work on your ideas while getting full support from your company",
      // desc2: "",
      descMob:
        "Got great ideas? Work on your ideas while getting support from the company to take your idea further.",
    },
    {
      iconClass: "jobseekerBenefitsIcon",
      head: "Family events",
      iconClass: "jobseekerBenefitsIcon",
      desc: "Not just a work place but a place where you can bring your family on special events.",
      // desc1: "Work on your ideas while getting full support from your company",
      // desc2: "",
      descMob:
        "Not just a work place but a place where you can bring your family on special events.",
    },
  ];
  return (
    <div>
      <div className="jobseekerBenefits">
        <SectionHeading
          head1="Benefits at Hirein5"
          head2=""
          desc1="Be a part of a technology company that creates value for its clients with every click, and builds a workforce that is always learning, growing and, having fun"
          desc2=""
          desc3=""
          descMob="Be a part of a technology company that creates value for its clients with every click, and builds a workforce that is always learning, growing and, having fun"
        />
        <div className="jobseekerBenefitsFlex margintop100 sectionWidth">
          <div className="jobseekerBenefitsFlexLeft">
            <img src={professional} alt="" />
            <h1 className="leftHeadPosition4 iconColorOrange">
              Professional
              <br /> Development
            </h1>
          </div>
          <div className="jobseekerBenefitsFlexRight paddingLeft100">
            {professionalDesc.map((data) => {
              return (
                <div className="jobseekerContent">
                  <div className="jobseekerBenefitsFlexRightHead iconColorOrange">
                    <FiCheck className={data.iconClass} />
                    <h2>{data.head}</h2>
                  </div>
                  {/* <p className="webHandler">{data.desc1}</p>
                  <p className="webHandler">{data.desc2}</p> */}
                  <p className="webHandler">{data.desc}</p>
                  <p className="mobileHandler">{data.descMob}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="jobseekerBenefitsFlex flexDirections sectionWidth">
          <div className="jobseekerBenefitsFlexLeft">
            <img src={international} alt="" />
            <h1 className="leftHeadPosition3 iconColorGreen">
              International
              <br /> Relocation
            </h1>
          </div>
          <div className="jobseekerBenefitsFlexRight">
            {internationalDesc.map((data) => {
              return (
                <div className="jobseekerContent">
                  <div className="jobseekerBenefitsFlexRightHead iconColorGreen">
                    <FiCheck className={data.iconClass} />
                    <h2>{data.head}</h2>
                  </div>
                  {/* <p className="webHandler">{data.desc1}</p>
                  <p className="webHandler">{data.desc2}</p> */}
                  <p className="webHandler">{data.desc}</p>
                  <p className="mobileHandler">{data.descMob}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="jobseekerBenefitsFlex  sectionWidth">
          <div className="jobseekerBenefitsFlexLeft">
            <img src={financial} alt="" />
            <h1 className="iconColorRed leftHeadPosition2">
              Beyond Fixed
              <br /> Salary
            </h1>
          </div>
          <div className="jobseekerBenefitsFlexRight paddingLeft100">
            {financialDesc.map((data) => {
              return (
                <div className="jobseekerContent">
                  <div className="jobseekerBenefitsFlexRightHead iconColorRed">
                    <FiCheck className={data.iconClass} />
                    <h2>{data.head}</h2>
                  </div>
                  {/* <p className="webHandler">{data.desc1}</p>
                  <p className="webHandler">{data.desc2}</p> */}
                  <p className="webHandler">{data.desc}</p>
                  <p className="mobileHandler">{data.descMob}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="jobseekerBenefitsFlex flexDirections sectionWidth">
          <div className="jobseekerBenefitsFlexLeft">
            <img src={healthImg} alt="" />
            <h1 className="leftHeadPosition1 iconColorBlue">
              Health & <br />
              Wellness
            </h1>
          </div>
          <div className="jobseekerBenefitsFlexRight">
            {healthDesc.map((data) => {
              return (
                <div className="jobseekerContent">
                  <div className="jobseekerBenefitsFlexRightHead iconColorBlue">
                    <FiCheck className={data.iconClass} />
                    <h2 className="iconColorBlue">{data.head}</h2>
                  </div>
                  {/* <p className="webHandler">{data.desc1}</p>
                  <p className="webHandler">{data.desc2}</p> */}
                  <p className="webHandler">{data.desc}</p>
                  <p className="mobileHandler">{data.descMob}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="jobseekerBenefitsFlex sectionWidth">
          <div className="jobseekerBenefitsFlexLeft">
            <img src={additional} alt="" />
            <h1 className="leftHeadPosition5 iconColorBlack">
              Work Life
              <br /> Integration
            </h1>
          </div>
          <div className="jobseekerBenefitsFlexRight paddingLeft100">
            {additionalDesc.map((data) => {
              return (
                <div className="jobseekerContent">
                  <div className="jobseekerBenefitsFlexRightHead iconColorBlack">
                    <FiCheck className={data.iconClass} />
                    <h2>{data.head}</h2>
                  </div>
                  {/* <p className="webHandler">{data.desc1}</p>
                  <p className="webHandler">{data.desc2}</p> */}
                  <p className="webHandler">{data.desc}</p>
                  <p className="mobileHandler">{data.descMob}</p>
                </div>
              );
            })}
          </div>
        </div>
        <h4 className="webHandler">
          * Please note that the above benefits may vary depending on your
          location, role, and employment status. Some of these benefits are
          subjected to approval by management. 
        </h4>
      </div>
    </div>
  );
};

export default JobseekerBenefits;
