import React from "react";
import "./SectionHeading.css";

const SectionHeading = (props) => {
  return (
    <div>
      <div className="sectionHeading sectionWidth">
        <div className="sectionHeadingBody">
          <div className="sectionHeadingBodyHead">
            <h1 className={props.headClass}>
              <span className="highLight">{props.highLight}</span>
              {props.head1}
            </h1>
            <h1 className={props.headClass}>{props.head2}</h1>
            <h1>{props.head3}</h1>
            <h1>{props.head4}</h1>
          </div>
        </div>
        <div className="sectionHeadingBodyDesc ">
          <p>{props.desc1}</p>
          <h3 className="headDescPara">{props.desc2}</h3>
          <p>{props.desc3}</p>
          <p>{props.desc4}</p>
        </div>
        <div className="sectionHeadingBodyDescMob">
          <p className="textAlignCenter">{props.descMob}</p>
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
