/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-whitespace-before-property */
import React from "react";
import "./ResourceCard.css";
import { FaArrowRight } from "react-icons/fa6";

const ResourceCard = (props) => {
  return (
    <div>
      <div className="resourceCard">
        <h1>{props.title}</h1>
        <p>{props.desc}</p>
        <div className="resourceCardReadTime">
          <h6>{props.author}</h6>
          <h6>|</h6>
          <h6>{props.date}</h6>
          <h6>|</h6>
          <h6>{props.read}</h6>
          <h6>|</h6>
          <h6>{props.type}</h6>
        </div>
        <div className="resourceCardImgContainer">
          <img className="resourceCardImg" src={props.Img} alt="" />
        </div>
        <div className="learnMore" onClick={props.onlearnmore}>
          <h6>Learn more</h6>
          <FaArrowRight />
        </div>
      </div>
    </div>
  );
};

export default ResourceCard;
